.title-bar {
    border-bottom: 1px solid #eceeef;
    background: white;
    margin-left: 70px;
}

.title-bar>.col-12 {
    padding-top: 11px;
    padding-bottom: 11px;
}

.title-bar h5, .title-bar h6 {
    margin-bottom: 0;
}

.title-bar h5 {
    font-size: 20px;
    line-height: 22px;
}

.title-bar h6 {
    font-size: 12.25px;
    line-height: 13.5px;
}

.clear-title-bar {
    margin-top: 58px;
}