@import '../node_modules/handsontable/dist/handsontable.full.css';

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

body, html, .popover {
    /*font-family: -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    /*font-family: "Barlow", Helvetica, Arial, sans-serif;*/
    font-family: "Goldman Sans", sans-serif;
    font-size: 13px;
    color: #444;
    font-feature-settings: 'tnum';
    -webkit-font-feature-settings: 'tnum';
    -moz-font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums;
}

button:hover, .pointer-hover:hover {
    cursor: pointer !important;
}

button:active {
    outline: none !important;
}

.dotted-underline {
    text-decoration: underline;
    text-decoration-style: dotted;
}

.dotted-underline:hover {
    cursor: pointer;
}

.popover {
    max-width: 350px;
    width: 350px;
}

a:not([href]):not([tabindex]) {
    color: #0f6180;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
    cursor: pointer;
    color: #0f6180;
    text-decoration: underline;
}

a {
    color: #0f6180;
}

.iconav-brand {
    background: #638da2;
}

.iconav-nav .nav-link {
    font-size: 12px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.iconav-nav .nav-link .icon {
    font-size: 16px !important;
    display: block;
}

.nav-link.active {
    color: #495057;
    background-color: #fff;
}

.modal-xl {
    min-width: 1250px;
}

.modal-xxl {
    min-width: 1350px;
}

.modal-header {
    background: #f8f9fa !important
}

.entity-list-column {
    width: 17%;
    position: fixed;
    top: 58px;
    left: 85px;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    min-height: calc(100% - 58px);
    height: calc(100% - 58px);
    overflow-y: auto;
}

.entity-list-collapsed {
    width: 50px;
    position: fixed;
    top: 58px;
    left: 70px;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    min-height: calc(100% - 58px);
    height: calc(100% - 58px);
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.05);
    text-align: center;
    padding-top: 5px;
}

.entity-list-collapsed:hover {
    cursor: pointer;
}

.clear-entity-list-column {
    margin-left: calc(17% + 1px);
}

.clear-entity-list-collapsed {
    margin-left: 51px;
}

.entity-list-toggle-button {
    background-color: #6c757d;
    font-weight: bold;
    font-size: 85%;
    /*color: #1997c6;*/
    color: white;
    border: none;
    text-align: right;
    padding: 3px;
    width: 30px;
    height: 30px;
}

.entity-list-toggle-button i {
    font-size: 120%;
}

.entity-search-item {
    /*background: rgba(99, 141, 162, 0.75);*/
    /*background: rgb(229, 229, 229);*/
    background: rgba(0, 0, 0, 0.05) !important;
}

.entity-list-item {
    /*border: none;*/
    color: inherit;
    border-color: rgba(0, 0, 0, 0.125) !important;
    border-top: 0;
    display: block;
}

.entity-list-item.nested {
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
}

.entity-list-item.nested.active {
    background: rgba(99, 141, 162, 0.75) !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
    color: white;
}

.entity-list-item.nested.active:hover {
    color: white;
}

.entity-list-item.nested.active:hover .text-muted {
    color: white !important;
}
.entity-list-item:hover {
    cursor: pointer;
    /*background: rgb(248, 249, 250);*/
    color: inherit;
    background: rgba(99, 141, 162, 0.25) !important;
    text-decoration: none;
}

.entity-list-item.active{
    background: rgba(99, 141, 162, 0.75) !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
}

.entity-list-item.active:hover{
    color: white;
}

.entity-list-item.active .text-muted{
    color: white !important;
}

.statcard-highlight {
    background: rgba(99, 141, 162, 0.75);
    color: white;
}

.statcard-highlight>.statcard-desc {
    color: inherit;
}

.statcard-highlight>.statcard-number {
    font-weight: bold;
}

.table-data {
    border: none !important;
}

.table-data thead {
    border: none !important;
}

.table-data th {
    font-weight: normal;
    color: #999;
}

.table-data>thead>tr>th, 
.table-data>tbody>tr>td,
.table-data>tfoot>tr>td {
    padding: 7px 7px 7px 0;
    line-height: 1.4em !important;
}

.table-data>tbody>tr>td, .table-data>thead>tr>th {
    border: none !important;
}

.table-condensed>thead>tr>th, 
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
    padding: 3px 3px 3px 0;
}

.top-dotted-border, .table-data>tbody>tr>td.top-dotted-border {
    border-top: 1px dotted #d0d1d3 !important;
}

.bottom-dotted-border, .table-data>tbody>tr>td.bottom-dotted-border, .table-data>thead>tr>th.bottom-dotted-border  {
    border-bottom: 1px dotted #d0d1d3 !important;
}

.bottom-solid-border {
    border-bottom: 1px solid #e9ecef !important;
}

.card-bg {
    background: rgba(0, 0, 0, 0.05) !important;
}

.clickable:hover {
    cursor: pointer;
}

.handsontable .htDimmed {
    background: #eee;
}

.ag-header-cell-text {
    white-space: normal !important;
}

.deemphasized-table-cell {
    background-color: #e8e8eb !important;
    color: #333;
    height: 100%;
}

.grid-style-table {
    /*color: rgba(0, 0, 0, 0.54);*/
    /*font-family: "Noto Sans", sans-serif;*/
    border-collapse: collapse;
    color: #444;
}

.grid-style-table th {
    /*background: rgb(245, 247, 247);*/
    /*rgb(189, 195, 199)*/
    background: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    color: #999;
}

.grid-style-table td {
    border-top: 1px solid rgba(0,0,0,.125) !important;
}

tr.border-0 td,
tr.border-0 th,
td.border-0,
th.border-0 {
    border: 0 !important;
}